//export const BaseUrl = localStorage.getItem("mode")=="demo"?"https://mmh-partner-portal-mock-api-6skz.onrender.com/":"https://api-uat.makemyhouse.com/"
export const BaseUrl = localStorage.getItem("mode")=="demo"?"https://mmh-partner-portal-mock-api-6skz.onrender.com/":"https://api.makemyhouse.com/"



export const otpSend = localStorage.getItem("mode")=="demo"?"partner/me/loginotp":"customer/me/loginotp"
export const veryfyOtp = localStorage.getItem("mode")=="demo"?"partner/me/veryfyotp":"customer/me/veryfyotp"
export const otpSendLink = "partner/customer/link"
export const verifyOtpLink = "partner/customer/verifyotp"
export const customerList = "partner/customer"

export const partnerList = "partner/customer/leads"

export const ResetPassword = "public/CustomerProfile/resetPassword"
export const createDraftOrder = "partner/orderv2"
export const orderProductList = "public/productsv2/"
export const AddOrderProduct = "partner/orderv2/products/"
export const ApplyOrderCoupon = "partner/orderv2/coupon/"
export const AddProductsPriority = "partner/orderv2/priority/"
export const GenerateActivationAmount ="partner/orderv2/activationAmount/"
export const GenerateOrder ="partner/orderv2/create/"
export const GetOrderList ="partner/orderv2"
export const GetCommissionList ="partner/Commission"
export const GetPartnerWalletBalance = "partner/Commission/wallet"
export const SendWithdrawAmount = "partner/Commission/withdrawal"
export const WithdrawalRequest = "partner/Commission/withdrawalRequest"
export const EditcustomersList = "/partner/Customer/"








export const POST = "POST"
export const GET = "GET"
export const PUT = "PUT"
export const appcode = "PATR"